var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"menu"},[_c('ul',{staticClass:"menu__list"},_vm._l((_vm.routes),function(route){return _c('router-link',{key:route.name,staticClass:"menu__link",attrs:{"to":{ name: route.name.toLowerCase() },"tag":"li"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu__icon",class:_vm.iconClass(route, isActive)},[_c('img',{attrs:{"src":_vm.routeImg(route.name),"alt":route.name}})]),_c('div',{staticClass:"menu__name"},[_vm._v(" "+_vm._s(route.name)+" ")])])]}}],null,true)})}),1)]),_c('mf-dropdown',{staticClass:"menu_mobile",attrs:{"open-from-right":false,"with-top-space":""}},[_c('div',{attrs:{"slot":"link"},slot:"link"},[_c('div',{staticClass:"menu__item menu__item_mobile"},[(_vm.currentRoute)?[_c('div',{staticClass:"menu__icon",class:_vm.iconClass(_vm.currentRoute, true)},[_c('img',{attrs:{"src":_vm.routeImg(_vm.currentRoute.name),"alt":_vm.currentRoute.name}})]),_c('div',{staticClass:"menu__name"},[_vm._v(" "+_vm._s(_vm.currentRoute.name)+" ")])]:[_c('div',{staticClass:"menu__icon"},[_c('img',{attrs:{"src":_vm.routeImg('portcall'),"alt":"portcall"}})]),_c('div',{staticClass:"menu__name"},[_vm._v(" Go to page ")])]],2)]),_c('div',{staticClass:"mobile-items"},[_vm._l((_vm.routes),function(route,index){return [(!_vm.currentRoute || route.name !== _vm.currentRoute.name)?[_c('router-link',{key:("mobile-" + (route.name)),staticClass:"menu__link",attrs:{"to":{ name: route.name.toLowerCase() },"tag":"li"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__item",attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"menu__icon",class:_vm.iconClass(route, isActive)},[_c('img',{attrs:{"src":_vm.routeImg(route.name),"alt":route.name}})]),_c('div',{staticClass:"menu__name"},[_vm._v(" "+_vm._s(route.name)+" ")])])]}}],null,true)}),(index !== _vm.routes.length - 1)?_c('mf-divider',{key:route.name}):_vm._e()]:_vm._e()]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }