<template>
    <mf-modal small :scrollable="false" @close="close">
        <div slot="header">
            Log out
        </div>
        <div class="info">
            To complete the logout, please close your browser.
        </div>
        <div slot="footer">
            <div class="info__buttons">
                <mf-button color="blue" @click="logOut">Log out</mf-button>
            </div>
        </div>
    </mf-modal>
</template>
<script>
    export default {
        methods: {
            close() {
                this.$emit('close');
            },
            logOut() {
                this.$store.dispatch('logOut');
                this.$router.push({ name: 'login' });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .info {
        text-align: center;
        color: $white;
        font-size: 18px;
        margin-bottom: $space-14;
    }

    .info__buttons {
        display: flex;
        justify-content: center;
    }
</style>
