<template>
    <div>
        <nav class="menu">
            <ul class="menu__list">
                <router-link
                    class="menu__link"
                    :to="{ name: route.name.toLowerCase() }"
                    tag="li"
                    v-for="route in routes"
                    :key="route.name"
                    v-slot="{ href, navigate, isActive }"
                >
                    <a class="menu__item" :href="href" @click="navigate">
                        <div class="menu__icon" :class="iconClass(route, isActive)">
                            <img :src="routeImg(route.name)" :alt="route.name" />
                        </div>
                        <div class="menu__name">
                            {{ route.name }}
                        </div>
                    </a>
                </router-link>
            </ul>
        </nav>
        <mf-dropdown class="menu_mobile" :open-from-right="false" with-top-space>
            <div slot="link">
                <div class="menu__item menu__item_mobile">
                    <template v-if="currentRoute">
                        <div class="menu__icon" :class="iconClass(currentRoute, true)">
                            <img :src="routeImg(currentRoute.name)" :alt="currentRoute.name" />
                        </div>
                        <div class="menu__name">
                            {{ currentRoute.name }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="menu__icon">
                            <img :src="routeImg('portcall')" alt="portcall" />
                        </div>
                        <div class="menu__name">
                            Go to page
                        </div>
                    </template>
                </div>
            </div>
            <div class="mobile-items">
                <template v-for="(route, index) in routes">
                    <template v-if="!currentRoute || route.name !== currentRoute.name">
                        <router-link
                            class="menu__link"
                            :to="{ name: route.name.toLowerCase() }"
                            tag="li"
                            :key="`mobile-${route.name}`"
                            v-slot="{ href, navigate, isActive }"
                        >
                            <a class="menu__item" :active="isActive" :href="href" @click="navigate">
                                <div class="menu__icon" :class="iconClass(route, isActive)">
                                    <img :src="routeImg(route.name)" :alt="route.name" />
                                </div>
                                <div class="menu__name">
                                    {{ route.name }}
                                </div>
                            </a>
                        </router-link>
                        <mf-divider v-if="index !== routes.length - 1" :key="route.name" />
                    </template>
                </template>
            </div>
        </mf-dropdown>
    </div>
</template>

<script>
    export default {
        computed: {
            currentRoute() {
                return this.routes.find(route => this.$route.name && this.$route.name.match(route.name.toLowerCase()));
            },
        },
        data() {
            return {
                routes: [
                    { name: 'Portcall', color: 'blue' },
                    { name: 'Quay', size: 'small', color: 'green' },
                    { name: 'Port', color: 'sea' },
                    { name: 'Custom', color: 'orange' },
                ],
            };
        },
        methods: {
            routeImg(routeName) {
                const routeIcons = require.context('@/assets/icons/menu', false, /\.svg$/);
                const routeIcon = routeName.toLowerCase();
                return routeIcons(`./${routeIcon}.svg`);
            },
            iconClass({ color, size }, isActive) {
                return [size && `menu__icon_${size}`, isActive && color && `menu__icon_${color}`];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .menu {
        display: none;
        @include media($lg) {
            display: block;
        }
    }

    .menu_mobile {
        display: block;
        @include media($lg) {
            display: none;
        }
    }

    .menu__list {
        display: flex;
        margin: 0 -$space-16;
    }

    .menu__link {
        padding: 0 $space-8;
        cursor: pointer;
    }

    .menu__item {
        display: flex;
        align-items: center;
        padding: $space-8;
        transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:hover {
            background: $marine-600;
            border-radius: $border-radius-8;
        }
    }

    .menu__item_mobile {
        padding: 0;
        &:hover {
            background: transparent;
            border-radius: 0;
        }
    }

    .menu__icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $marine-600;
        border-radius: $border-radius-8;
        padding: 5px;
        width: 32px;
        height: 32px;
        @include media($md) {
            width: 40px;
            height: 40px;
        }
    }

    .menu__icon_small {
        padding: 8px;
    }

    .menu__icon_blue {
        background: $blue-500;
    }

    .menu__icon_green {
        background: $green-700;
    }

    .menu__icon_sea {
        background: #0ba59b;
    }

    .menu__icon_orange {
        background: $orange-500;
    }

    .menu__name {
        color: $white;
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        padding-left: $space-8;
        @include media($lg) {
            padding-left: $space-12;
        }
        @include media($xlg) {
            font-size: $font-18;
        }
    }

    .mobile-items {
        top: 100px;
    }
</style>
